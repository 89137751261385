<template>
  <div>
    <FormIdView
      v-if="conditionForm"
      :FormID="formDetail"
      @closeModalForm="closeModalForm"
    />
    <FormResult
      v-if="conditionForm2"
      :FormID="formDetailResult"
      @closeModalForm="closeModalForm"
    />
    <FormDownload
      v-if="isDownloadFormSidebarActive"
      :is-download-form-sidebar-active.sync="isDownloadFormSidebarActive"
      :itemDownload="itemDownload"
      @downloadFormSuccess="downloadFormSuccess"
    />
    <FormEdit
      v-if="isEditFormSidebarActive"
      :is-edit-form-sidebar-active.sync="isEditFormSidebarActive"
      :itemEdit="itemEdit"
      @editFormSuccess="editFormSuccess"
    />
    <FormAdd
      :is-add-new-form-sidebar-active.sync="isAddNewFormSidebarActive"
      @createForm="createForm"
    />
    <b-card>
      <TitleTable titleTable="Forms" iconTable="FileTextIcon" />
      <div class="m-1">
        <!-- Table Top -->
        <b-row>
          <b-col
            md="12"
            lg="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("Show") }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("Entries") }}</label>
          </b-col>
          <!-- {{ $t("Search") }} -->
          <b-col lg="6" md="12">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
              <b-button
              v-if="$can('store', 'module_documents_and_forms.forms.edit_form')"
                variant="primary"
                @click="isAddNewFormSidebarActive = true"
                :disabled="loading"
              >
                <span v-if="!loading" class="text-nowrap"
                  >{{ $t("Add") }} Form</span
                >
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        :items="items"
        :fields="fields"
        striped
        bordered
        responsive
        hover
        class="position-relative"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(roles)="data">
          <span
            v-for="item in data.item.roles"
            :key="item.id"
            class="align-text-top text-capitalize"
          >
            <b-badge :variant="resolveUserRoleVariant(item)"
              >{{ item }}
            </b-badge>
          </span>
        </template>
        <template #cell(actions)="data">
          <b-button
            v-if="$can('show', 'module_documents_and_forms.forms.edit_form')"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            v-b-tooltip.hover.top="'View'"
            @click="viewForm(data.item.id)"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>

          <b-button
            v-if="$can('show', 'module_documents_and_forms.forms.edit_form')"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-warning"
            class="btn-icon"
            v-b-tooltip.hover.top="'View Results'"
            @click="resultForm(data.item.url)"
          >
            <feather-icon icon="AlignJustifyIcon" />
          </b-button>

          <b-button
            v-if="$can('show', 'module_documents_and_forms.forms.edit_form')"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon"
            v-b-tooltip.hover.top="'Download Report'"
            @click="downloadForm(data.item.url)"
          >
            <feather-icon icon="DownloadIcon" />
          </b-button>

          <b-button
            v-if="$can('update', 'module_documents_and_forms.forms.edit_form')"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-primary"
            class="btn-icon"
            v-b-tooltip.hover.top="'Edit'"
            @click="editForm(data.item)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <b-button
            v-if="$can('destroy', 'module_documents_and_forms.forms.edit_form')"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon"
            v-b-tooltip.hover.top="'Delete'"
            @click="deleteForm(data.item.id)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </template>
      </b-table>
      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged"
      />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>
<script>
import {
  BCard,
  BTable,
  BBadge,
  BButton,
  VBTooltip,
  BRow,
  BCol,
} from "bootstrap-vue";
import FormIdView from "./FormIdView.vue";
import FormResult from "./FormResult.vue";
import FormDownload from "./FormDownload.vue";
import FormEdit from "./FormEdit.vue";
import FormAdd from "./FormAdd.vue";

import TitleTable from "@/components/TitleTable.vue";
import ToastNotification from "@/components/ToastNotification";
import PaginationTable from "@/components/PaginationTable";

import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import GoBack from "@/components/ButtonBack.vue";

import axiosFr from "@/services/admin/form";
import { ref } from "vue-demi";
export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BButton,
    BRow,
    BCol,
    FormIdView,
    FormResult,
    FormDownload,
    FormEdit,
    FormAdd,
    TitleTable,
    ToastNotification,
    PaginationTable,
    vSelect,
    GoBack,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      items: null,
      fields: [
        { key: "id", label: "ID", sortable: true },
        { key: "name", label: "Name" },
        // A column that needs custom formatting
        { key: "description", sortable: true, class: "th-class-adjusted" },
        // A regular column
        { key: "roles", label: "Roles", sortable: true },
        // A virtual column made up from two fields
        { key: "actions", label: "Actions" },
      ],
      conditionForm: false,
      formDetail: null,
      conditionForm2: false,
      formDetailResult: null,
      isDownloadFormSidebarActive: false,
      itemDownload: null,
      loading: false,
      isEditFormSidebarActive: false,
      isAddNewFormSidebarActive: false,
      itemEdit: null,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      perPage: 10,
      currentPage: {
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],
      filtro: false,
      formFilter: null,
    };
  },
  mounted() {
    this.getForms();
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getForms();
      } else {
        this.searchFilter(this.formFilter);
      }
    },
  },
  methods: {
    getForms() {
      axiosFr
        .formListAdmin(this.perPage)
        .then(
          ({ registro: { data, current_page, total, per_page, ...res } }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          }
        );
    },
    viewForm(id) {
      axiosFr.formView(id).then(({ registro }) => {
        this.formDetail = registro;
        this.conditionForm = true;
      });
    },
    resultForm(url) {
      this.formDetailResult = url.split("/")[3];
      this.conditionForm2 = true;
    },
    editForm(item) {
      this.isEditFormSidebarActive = true;
      this.itemEdit = ref(JSON.parse(JSON.stringify(item)));
    },
    editFormSuccess(value) {
      if (value) {
        this.$refs.toast.success("Type Form updated successfully");
      } else {
        this.$refs.toast.error("Error updating type Form");
      }
      this.isEditFormSidebarActive = false;
      this.pageChanged(this.currentPage.page);
    },
    createForm({ status }) {
      if (status) {
        this.$refs.toast.success("Form type added successfully");
      } else {
        this.$refs.toast.danger("Form type not added");
      }
      this.isAddNewFormSidebarActive = false;
      this.pageChanged(this.currentPage.page);
    },
    downloadForm(url) {
      this.itemDownload = parseInt(url.split("/")[3]);
      this.isDownloadFormSidebarActive = true;
    },
    downloadFormSuccess(value) {
      if (value) {
        this.$refs.toast.success("Report successfully added and downloaded");
        this.isDownloadFormSidebarActive = false;
      } else {
        this.$refs.toast.danger("Report not added");
        this.isDownloadFormSidebarActive = false;
      }
      this.pageChanged(this.currentPage.page);
    },
    deleteForm(id) {
      this.$swal({
        title: '<span class="font-weight-bolder">Are you sure?</span>',
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axiosFr
            .formDelete(id)
            .then((response) => {
              this.$refs.toast.success("Form deleted successfully");
              this.pageChanged(this.currentPage.page);
            })
            .catch((error) => {
              this.$refs.toast.danger("Error deleting form");
            });
        }
      });
    },
    closeModalForm() {
      this.conditionForm = false;
      this.conditionForm2 = false;
    },
    statusForm(role) {
      if (role === "ENABLED") return "success";
      if (role === "DELETED") return "danger";
      if (role === "NURSE") return "info";
      if (role === "OMA") return "info";
      if (role === "SUPER-ADMIN") return "danger";
      return "primary";
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosFr
          .formPagination(this.perPage, page,'admin')
          .then(({ registro: { data, total, current_page, ...res } }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      } else {
        axiosFr
          .formListAdmin(this.perPage, page, this.formFilter)
          .then(({ data, total, current_page, ...res }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      }
    },
    resolveUserRoleVariant(role) {
      if (role === "FRONT-DESK") return "warning";
      if (role === "ADMIN") return "success";
      if (role === "NURSE") return "info";
      if (role === "OMA") return "info";
      if (role === "SUPER-ADMIN") return "danger";
      return "primary";
    },
  },
};
</script>

<style lang="scss"></style>
