<template>
  <b-modal
    v-model="condicionForm"
    @hide="onHide"
    ok-title="Ok"
    ok-only
    centered
    size="xl"
  >
      <iframe
        :id="'JotFormIFrame' + FormID"
        title="Warme Leads"
        onload="window.parent.scrollTo(0,0)"
        allowtransparency="true"
        allowfullscreen="true"
        allow="geolocation; microphone; camera"
        :src="`https://www.jotform.com/tables/${FormID}`"
        frameborder="0"
        style="min-width: 100%; height: 80vh; border: none"
        scrolling
      >
      <!-- //https://www.jotform.com/tables/220963811361655 -->
      </iframe>
  </b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";
export default {
  components: {
    BModal,
  },
  props: {
    FormID: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      condicionForm: true,
    };
  },
  methods: {
    onHide() {
      this.$emit("closeModalForm", false);
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 360px) {
  iframe#JotFormIFrame {
    min-height: 2930px;
  }
}
</style>