import { jotAxios } from '../index'

const api = process.env.VUE_APP_API_JOTFORM

/**
 * This function will return a list of forms from the JotForm API.
 * @returns An array of objects.
 */
const formList = async () => {
    try {
        return await jotAxios.get(`user/forms?apikey=${api}&orderby=id`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const formView = async (id) => {
    try {
        return await jotAxios.get(`form/${id}?apikey=${api}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const formCreateReport = async (id, item) => {
    try {
        return await jotAxios.post(`form/${id}/reports?apikey=${api}`, item).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const formDelete = async (id) => {
    try {
        return await jotAxios.delete(`form/${id}?apikey=${api}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    formList,
    formCreateReport,
    formView,
    formDelete,
}