<template>
  <b-sidebar
    id="add-new-form-sidebar"
    :visible="isDownloadFormSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-download-form-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Download form</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group :label="$t('Form.Name')" label-for="name">
              <b-form-input
                id="name"
                v-model="option.title"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="title Report"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Type File"
            rules="required"
          >
            <b-form-group label="Type File" label-for="type_file">
              <v-select
                id="type_file"
                v-model="option.list_type"
                :state="getValidationState(validationContext)"
                :options="optionFiles"
                :reduce="(option) => option.value"
                :clearable="false"
                label="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="loading"
            >
              <span v-if="!loading">Download</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-danger"
              @click="hide"
            >
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SpinnerLoading from "@/components/SpinnerLoading";

import vSelect from "vue-select";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { ref } from "@vue/composition-api";

import axiosF from "@/services/external-settings/forms";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    SpinnerLoading,

    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isDownloadFormSidebarActive",
    event: "update:is-download-form-sidebar-active",
  },
  props: {
    isDownloadFormSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemDownload: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const optionFiles = [
        { name: "Excel", value: "excel"},
        { name: "CSV", value: "csv"},
        { name: "Table", value: "table"},
    ]

    const optionTem = {
      title: "",
      list_type: "",
    };
    const option = ref(JSON.parse(JSON.stringify(optionTem)));

    const resetuserData = () => {
      option.value = JSON.parse(JSON.stringify(optionTem));
    };

    const loading = ref(false);
    const onSubmit = async() => {
      loading.value = true;
      const formData = new FormData();
      formData.append("title", option.value.title);
      formData.append("list_type", option.value.list_type);
      formData.append('fields', 'id,dt,ip')
      
      await axiosF
        .formCreateReport(props.itemDownload, formData)
        .then(({content}) => {
          loading.value = false;
          resetuserData();
          window.open(content.url, '_blank')
          emit("downloadFormSuccess", true);
        })
        .catch((e) => {
          console.log(e)
          emit("downloadFormSuccess", false);
          loading.value = false;
        });
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);
    return {
      loading,
      option,
      optionFiles,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style>
</style>